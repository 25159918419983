
import { useState } from "react";
import headicon from "../../assets/img/H&S.png";
import headShort from "../../assets/img/H&S_short.png"

interface dataMenu {
    head:boolean,
    
} 

export const Headpart = (props:dataMenu)=>{


    const clickMenuItem = (goTo:string)=>{
        
        let goToElement:any = document.getElementsByClassName("intro-info")
        
        switch(goTo){
            case "soft":
                goToElement =  document.getElementsByClassName("cnt-card-soft");
                break;
            case "solutions":
                goToElement =  document.getElementsByClassName("cnt-card-solutions");
                break;
            case "partner":
                goToElement =  document.getElementsByClassName("cnt-carrusel");
                break;
            case "contact":
                goToElement =  document.getElementsByClassName("cnt-form");
                break;
        }

        window.scrollTo({top:goToElement[0].offsetTop-140,left:0,behavior:'smooth'});
        onClickMenu()
    }

    const [stateMenu,setStateMenu] =  useState<string>("")
    const onClickMenu = ()=>{
        if(stateMenu===""){ setStateMenu("change") }
        else if(stateMenu==="change"){ setStateMenu("") }
    }

    return(
        <header className={`row ${props.head && "short"}`}>
            <div className="col-9 cnt-img" >
                <img className="img-full"  src={headicon} />
                <img className="img-short"  src={headShort} />
                <div className="title" >Integradores de Tecnología</div>
            </div>
            <div className="col-3 " >
                <div onClick={onClickMenu} className={`cnt-menu ${stateMenu}`} >
                    <div className="menu-background" >
                        <div className="menu-bar bar-1" ></div>
                        <div className="menu-bar bar-2"></div>
                        <div className="menu-bar bar-3"></div>
                    </div>
                </div>
            </div>
            <div className={`cnt-lateral-menu ${stateMenu}`} >
                <div className="menu">
                <div className="item" onClick={()=>clickMenuItem("init")} >
                        <div className="back" ></div>
                        <div className="txt" >Inicio</div>
                    </div>
                    <div className="item" onClick={()=>clickMenuItem("soft")} >
                        <div className="back" ></div>
                        <div className="txt" > Nuestros Softwares</div>
                    </div>
                    <div className="item"  >
                        <div className="back" ></div>
                        <div className="txt" ><a href="https://cloud-tpv.hysintegrar.com" target="_blank" rel="noreferrer" >TPV </a></div>
                    </div>
                    <div className="item" >
                        <div className="back" ></div>
                        <div className="txt" > <a href="https://cloud-kdocs.hysintegrar.com/" target="_blank" rel="noreferrer" >KDOCS</a></div>
                    </div>
                    <div className="item" onClick={()=>clickMenuItem("contact")} >
                        <div className="back" ></div>
                        <div className="txt" > Contactanos </div>
                    </div>
                </div>
            </div>
        </header>
    )

}